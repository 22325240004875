body {
  font-family: "Open Sans", sans-serif;
  &.weight600 {
    font-weight: 600;
  }
}

html {
  scroll-behavior: smooth;
}

.navbar {
  padding: 0;
}

.theme {
  padding: 16px;
}

.header {
  border-top: 3px solid #506c64;
}

.theme-white {
  color: #666;
  background-color: #fff;
}

.theme-logo {
  color: #fff;
  background-color: #1a1919;
}

.theme-menu {
  background-color: #343a40;
  padding-left: 16px;
  padding-right: 16px;
  color: #fff;
}

.theme-primary {
  color: #fff;
  background-color: #506c64;
}

.theme-footer {
  background-color: #75b8c8;
  color: #333;
}
.theme-footer a {
  color: #333;
}
.theme-footer ul {
  list-style-type: none;
  margin-left: 10px;
  padding: 0;
}

.powered-by {
  text-align: right;
}
.powered-by img {
  max-width: 100px;
}

.h1Divider {
  border-bottom: 3px solid #333;
  max-width: 50px;
}

.theme-grey {
  background-color: #f2f2f2;
  color: #000;
}

.bg-light.spacer {
  min-height: 1px;
}

.services .card {
  min-height: 283px;
}

.header a {
  color: #333;
}

.btn-sq-lg {
  width: 150px !important;
  height: 150px !important;
  margin-top: 10px;
}

.btn-sq {
  width: 100px !important;
  height: 100px !important;
  font-size: 10px;
}

.btn-sq-sm {
  width: 50px !important;
  height: 50px !important;
  font-size: 10px;
}

.btn-sq-xs {
  width: 25px !important;
  height: 25px !important;
  padding: 2px;
}
